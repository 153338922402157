.userNameContainer {
    display: flex;
    /* background-color: red; */
    align-items: center;
    width: 240px;
    overflow: hidden;
    box-sizing: border-box;
    padding: 20px;
    height: 70px;
    /* justify-content: center; */
    margin-top: 8px;
    font-family: 'Roboto';
}

.menu-item .MuiListItemText-root span {
    color: #928e8e;
    font-size: 16px;
    font-weight: 400;
}

.menu-item .active .MuiListItemText-root span {
    color: #424242;
    font-size: 16px;
    font-weight: 600;
}
.MuiDrawer-root .MuiPaper-root {
    background-color: white;
}
.menuTitleStore {
    margin-top: 17px;
    margin-bottom: 35px;
    font-weight: 600;
    font-size: 18px;
}

.imgLogoMenuContainer {
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

.containerAllMenu {
    background-color: white;
    height: 100%;
}
