.gmapsContainer {
    min-height: 50vh;
    width: 100%;
    min-width: 20vw;
    max-width: 20vw;
    /* border: solid 1px; */
    border-radius: 5px;
    margin-top: 58px;
    /* overflow: hidden; */
    margin-left: 20px;
    transform: translateY(-58px);
    box-shadow: 2px 3px 2px -2px rgba(0, 0, 0, 0.85);
}

.gmapsContainer .gmapsAddressContainer .gmapsDeliveryHeaderDefault{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .containerAllDeliveriesContainer .MuiTable-root .MuiTableBody-root .MuiTableRow-root{
    background-color: #F9FAFE !important;
  }

  .containerAllDeliveriesContainer .MuiTable-root .MuiTableBody-root{
    background-color: #F9FAFE;
  }
  

  .containerAllDeliveriesContainer .MuiToolbar-gutters{
      margin-top: 20px;
      margin-bottom: 20px;
  }

.gmapsAddressContainer {
    padding: 10px;
    height: 39px;
    background-color: whitesmoke;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.gmapsTitle {
    font-size: 15px;
    font-weight: 600;
    margin: 0;
    margin-left: 10px;
}

.markerContainer {
    background-color: light;
    border-radius: 50%;
    transform: translate(-50%, -100%);
    position: absolute;
}

.bubbleContainer {
    display: flex;
    flex-direction: column;
    background-color: black;
    position: absolute;
    padding: 10px;
    top: -80px;
    opacity: 0.7;
    border-radius: 5px;
    color: white;
    animation: appearMaps 0.3s;
}

@keyframes appearMaps {
    from {
        opacity: 0;
    }
    to {
        opacity: 0.7;
    }
}
.bubbleContainer > span {
    font-size: 15px;
}

.mapsIcons {
    position: absolute;
    bottom: -30px;
}
