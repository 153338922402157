.historyPopupClient{
  padding: 15px;
}


/* .containerAllHistoryListContainer{
  margin-left: 35px;
  margin-right: 35px;
} */

/* .containerAllHistoryListContainer .titlePage{
  color: green;
} */

.historyPopupClientRow{
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.buttonCoordinateClient{
  margin-left: 20px;
}


.containerAllHistoryListContainer .MuiTable-root .first{
margin: auto;
}

.containerAllHistoryListContainer .MuiTable-root .MuiTableBody-root{
  background-color: #F9FAFE;
}

.containerAllHistoryListContainer .titlePage {
  margin-bottom: 25px;
  margin-top: 35px;
}

.containerAllHistoryListContainer .MuiToolbar-regular{
  background-color: #EFEFEF;
}

.containerAllHistoryListContainer .defaultPageContainer .MuiPaper-root .MuiTablePagination-root .MuiToolbar-regular{
  background-color: #F9FAFE;
}