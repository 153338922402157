.containerAllDeliveriesContainer .MuiTable-root .first{
  margin: auto;
  }
  
  .containerAllDeliveriesContainer .MuiTable-root .MuiTableBody-root{
    background-color: #F9FAFE;
  }
  
  .containerAllDeliveriesContainer .titlePage {
    margin-bottom: 25px;
    margin-top: 35px;
  }
  
  .containerAllDeliveriesContainer .MuiToolbar-regular{
    background-color: #EFEFEF;
  }
  
  .containerAllDeliveriesContainer .defaultPageContainer .MuiPaper-root .MuiTablePagination-root .MuiToolbar-regular{
    background-color: #F9FAFE;
    margin: 0px;
  }

  .containerAllDeliveriesContainer .list-page .MuiPaper-root{
    height: min-content;
  }

  .containerAllDeliveriesContainer .defaultPageContainer .MuiTableCell-root .MuiTypography-root div{
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .containerAllDeliveriesContainer .containerIndicator{
    border:0.5px solid #C9C9C9;
    background-color: #FAFAFA;
    margin-right: 25px;
    padding: 9px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .containerAllDeliveriesContainer .containerIndicator .titleIndicatorSpan{
    margin-bottom: 4px;
    color: #857E8B;
     font-size: 12px;
  }

  .containerAllDeliveriesContainer .containerIndicator .contentIndicatorSpan{
    font-weight: bold;
    color: #444D59;
  }

  .containerAllDeliveriesContainer .indicatorDeliveryMen{
    display: flex;
    flex-direction: row;
    margin-top: 35px;
    margin-bottom: 25px;
    margin-left: 35px;
  }

  .containerAllDeliveriesContainer .titleAndIndicatoresContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
  }

.inactiveProviderList{
    display: flex;
    flex-direction: column;
    padding: 15px;
  }

.inactiveProvider{
    margin-bottom: 10px;
  }

.switchPopup{
    width: 165px;
    border:0.5px solid #C9C9C9;
    background-color: #FAFAFA;
    border-radius: 15px;
    padding: 10px;
}

.redSwitch .MuiSwitch-thumb {
  color: #ffffff;
}

.redSwitch .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: #ff0000;
}


