.dashboardContainer {
    background-color: #EFEFEF;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 8px;
}



.iframeDashboard{
    background-color: white;
    padding: 15px;
    margin-top: 35px;
    width: 98%;
    height: 98%;
}