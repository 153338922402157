.incidentContainer {
    display: flex;
    flex-direction: column;
    /* border: solid 1px blue; */
    gap: 30px;
}

.incidentContainer .rowFlex{

    display: flex;
    flex-direction: row;
}
.incidentBlock {
    /* border: solid 1px red; */
    width: max-content;
    margin: 15px;
}

.createDialogAllContainer .incidentORderId .MuiTypography-root{
    font-size: 16px;
    font-family: inherit;

}

.createDialogAllContainer .incidentORderId{
    padding-left: 8px;
}

.createDialogAllContainer .MuiDialog-paper{
    max-width: none;
}


.createDialogAllContainer .MuiDialog-paper .inputIncident{
    width: 200px;
}

.editDialogAllContainer .MuiDialog-paper .inputIncident{
    width: 200px;
}

.editDialogAllContainer .MuiDialog-paper{
    max-width: none;
}

.editDialogAllContainer .incidentORderId .MuiTypography-root{
    font-size: 16px;
    font-family: inherit;

}

.editDialogAllContainer .incidentORderId{
    padding-left: 8px;
}



.containerAllIncidentsListContainer .defaultPageContainer .MuiPaper-root .MuiTablePagination-root .MuiToolbar-regular{
    background-color: #F9FAFE;
  }

  .rowInput{
    margin-right: 15px;
    margin-left: 15px;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
