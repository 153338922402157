.DMSelectorContainer {
    /* background-color: pink; */
    padding: 20px;
}

.dmSelectorPopup .MuiDialog-paper{
    max-width: 700px;
}

.dmSelectorPopup .MuiDialog-paper .yesDisponibilityIcon {
    color: #368182;
}

.dmSelectorPopup .MuiDialog-paper .noDisponibilityIcon {
    color: #F71D23;
}