.modalCancelOrder{
  padding: 10px;
  /* justify-content: center; */
}
.modalCancelErrorIcon{
  margin-left: auto;
  margin-right: auto;
  color: red;
  font-size: 18px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 5px;
  margin-bottom: 5px;
}
.textModalCancelOrder{
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  text-align: center;
}

.containerAllOrdersListContainer .titlePage{
  margin-bottom: 20px;
}

.containerAllOrdersListContainer .defaultPageContainer .MuiPaper-root .MuiTablePagination-root .MuiToolbar-regular{
  background-color: #F9FAFE;
}

