.odContainer {
    display: flex;
    width: 80vw;
    max-width: 1500px;
    padding: 25px;
    border-radius: 10px;
    background-color: #f5f5f5;
}

.odPageContainer {
    flex: 1 1 0px;
    border: solid 1px #e7e7e7;
    padding: 20px;
}

.odSpanGrey {
    color: #928e8e;
    font-size: 15px;
    font-weight: 100;
    font-size: 16px;
}
.odSpanBold {
    /* color: grey; */
    font-size: 16px;
    font-weight: 500;
    color: #424242;
}

/* # # # # # # # # # # ORDER DETAIL - PAGE 1 # # # # # # # # # # # #  */

.odTitle {
    font-weight: 600;
    color: #444d59;
    font-size: 17px;
    margin-left: 7px;
}

.odProduct {
    /* border: solid 1px red; */
    padding: 10px;
}

.odProductTitle {
    color: #928e8e;
    font-weight: bold;
    font-size: 16px;
}
/* 
  .odItemContenair {
    border: solid 1px green;
  } */

.odItemTitleQuantity {
    color: #ff0000;
    font-weight: bold;
    font-size: 16px;
    margin-right: 10px;
}

.odItemTitle {
    font-weight: bold;
    margin-right: 10px;
    font-size: 17px;
    color: #424242;
}

.pricePerQuantity {
    position: absolute;
    right: 0;
    top: 20px;
    font-size: 12px;
    font-weight: 200;
}

.specifBox {
    line-height: 24px;
    font-weight: 300;
    font-size: 16;
    margin-top: 20px;
}

.odNote {
    display: flex;
    max-width: 280px;
    /* justify-content: center; */
    gap: 8px;
    align-items: center;
    color: #4f6787;
    line-height: 15px;
    font-weight: 300;
    font-size: 16;
    margin-top: 5px;
}

.orderDetailCancelReason {
    margin-top: 25px;
    font-size: 16px;
    font-weight: 500;
    color: #424242;
}
.cancelReasonReason {
    margin-top: 15px;
}
.statusDateOrderDetail {
    font-size: 17px;
    font-weight: 600;
    color: #444d59;
}

.DatetimeDateOrderDetail {
    color: #424242;
    font-size: 15px;
    font-weight: 100;
}

/* # # # # # # # # # # TIME LINE - PAGE 2 # # # # # # # # # # # #  */

.odTimeLineItem {
    background-color: #ebeaea;
    padding: 8px;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 50px;
    margin-right: 30px;
    position: relative;
    border-radius: 10px;
}

/* GREY BALL */
.odTimeLineItem::before {
    background-color: #ebeaea;
    width: 30px;
    height: 30px;
    content: '';
    border-radius: 50%;
    position: absolute;
    top: 4px;
    left: -50px;
}

/* GREY BAR */

.odTimeLineItemBar {
    background-color: #ebeaea;
    width: 3px;
    height: 85%;
    content: '';
    position: absolute;
    top: 33px;
    left: -36px;
}

/* GREY TRIANGLE */
.odTimeLineItem > span:first-child::before {
    content: '';
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #ebeaea;
    position: absolute;
    top: center;
    left: -9px;
}

.odTimeLineItem > span:first-child {
    /* background-color: red; */
    font-weight: bold;
    color: rgb(77, 77, 77);
    padding-bottom: 5px;
}
.odTimeLineItem:nth-child(1) {
    /* background-color: red; */
    /* font-weight: ; */
    color: rgb(77, 77, 77);
    padding-bottom: 5px;
}

/* # # # # # # # # # # INVOICE - PAGE 3 # # # # # # # # # # # #  */

.odInvoiceLine {
    display: flex;
    justify-content: space-between;
}
/* .odInvoiceLineBold > span {
    color: red;
  } */
