body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiPaper-root .MuiTableCell-root {
  text-align: center;
}

.MuiTableBody-root{
  background-color: #F9FAFE;
}

.MuiToolbar-root{
  background-color: #F9FAFE;
}

#main-content{
  background-color: #EFEFEF;
}

.MuiToolbar-regular{
  background-color: #EFEFEF;
}

.defaultPageContainer {
  padding-left: 40px;
  padding-right: 40px;
}

.titlePage{
  /* margin-left: 15px; */
  margin-top: 25px;
  color: #444D59;
  font-weight: 600;
  font-size: 18px;
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  width: fit-content;
}

.MuiTableRow-head{
  background-color: white;
}

.layout .MuiAppBar-positionFixed .MuiToolbar-dense{
  background-color: #348282;
}

.saveButton{
  justify-content: flex-end;
  display: flex;
  width: 100%;
  background-color: white;
}

.saveButton .MuiButtonBase-root{
  margin-right: 30px;
  background-color: #444D59;
  color: white;
  font-size: 16px;
  font-weight: 500;
  text-transform: none;
  padding-left: 48px;
  padding-right: 48px;
}

.saveButton .MuiToolbar-root {
  background-color: white;
}