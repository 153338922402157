.toolBarCustom {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: #f0f0f0;
    gap: 20px;
    animation: appearToolbar 0.1s;
    padding: 10px;
}
.toolBarCustom button {
    animation: appearToolbar 0.1s;
}

@keyframes appearToolbar {
    from {
        height: 0;
        content: '';
    }
    to {
        height: 50px;
    }
}
